/* Cookie headers has format => "name1=value1; name2=value2; name3=value2"
 Example usage:
  extractCookie("name1=value1; name2=value1; name3=value1" ,"name1") => "value1"
  extractCookie("name1=value1; name2=value1; name3=value1" ,"name10") => undefined

*/
export function extractCookie(cookieHeader: string | undefined, cookieKey: string): string | null {
  if (!cookieHeader) {
    return null;
  }
  const cookieKeyExpression = new RegExp(`(^| )${cookieKey}=([^;]+)`);
  const match = cookieHeader.match(cookieKeyExpression);

  if (!match || !match?.[2]) {
    return null;
  }

  return decodeURIComponent(match[2]);
}

/**
 * Gets cookie value for a cookie name in browser
 * @param cookieName
 * @returns cookie value or undefined if cookie does not exist, or no access to cookies
 */
export function getCookieValue(cookieName: string): string | undefined {
  if (!window.document?.cookie) {
    return undefined;
  }

  const cookieList = window.document.cookie.split(";");
  const cookie = cookieList.find((cookie) => cookieName === cookie.split("=")[0].trim());
  if (!cookie) {
    return undefined;
  }
  return cookie.split("=")[1];
}